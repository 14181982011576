function Contact() {
    return (
  <div>
  <h2 className="text-8xl font-bold underline">Contact!</h2>    

  </div>
  
  
    );
  }

  export default Contact;
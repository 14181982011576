import './about.css';

function About () {
    return (
  <div className="aboutme">


  <span className="aboutdot">    </span>



  </div>
  
  
    );
  }

  export default About;
import './home.css';


function Home() {
    return (
  <div className="home">
  <span class="dot">  <h2 className="text-8xl font-bold underline hhead">Steph Heise</h2>    
</span>


  </div>
  
  
    );
  }

  export default Home;